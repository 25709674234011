import React, { useState, useEffect } from 'react';
import Layout, { Content } from '../../components/Layout';
import { useAuth } from '../../hooks/useAuth';
import { useApplication } from '../../hooks/useApplication';
import Navigation from '../../components/mybunac/navigation';
import AddOns from '../../components/mybunac/Application/AddOns';
import ThankYou from '../../components/forms/ThankYou';
import styled from 'styled-components';

const ContentLayout = styled.div`
  display: block;
  height: 100%;
`;

const Addon = ({ data, pageContext }) => {
  const { isAuthenticated } = useAuth();
  const {
    application,
    setApplicationId,
    availableAddOns,
    setAddOn,
    unsetAddOn,
    updateProducts,
  } = useApplication();
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    const applicationId = window.location.hash.substr(1);
    setApplicationId(applicationId);

    // eslint-disable-next-line
  }, [isAuthenticated]);

  const changeAddOns = async addOns => {
    const changedAddOns = addOns.filter(addOn => {
      const changedAddOn = availableAddOns.find(({ id }) => id === addOn.id);
      return addOn.selected !== changedAddOn.selected;
    });

    for (const addOn of changedAddOns) {
      if (addOn.selected) {
        await setAddOn(addOn);
      } else {
        await unsetAddOn(addOn);
      }
    }

    await updateProducts();
    await setUpdated(true);
  };

  return (
    <Layout title={'Addon'}>
      <Navigation />
      <Content>
        <ContentLayout>
          {!updated && (
            <AddOns
              application={application}
              addons={availableAddOns}
              onChange={changeAddOns}
            />
          )}
          {updated && (
            <ThankYou text="We are now processing you Add-On selection" />
          )}
        </ContentLayout>
      </Content>
    </Layout>
  );
};

export default Addon;