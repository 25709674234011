import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Grid, TextField } from '@material-ui/core';
import Text from '../../../common/Text';
import Price from '../../../common/Price';
import { BlockTitle } from '../../../common/Headline';

const Line = styled.div`
  height: 9px;
  width: 100%;
  background-color: #515151;
`;

const StyledButton = styled.button`
  margin-top: 16px;
  color: rgba(0, 0, 0, 0.3);
  height: 40px;
  font-family: Arial;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  background-color: #dcdcdc;
  padding-left: 32px;
  padding-right: 32px;
  margin-right: 16px;

  &.selected {
    background-color: #ffad01;
    color: #fff;
    font-weight: 100;
  }
`;

export default ({
  addon: { title, description, price, selected, paid, unit, quantity, ...rest },
  currency,
  onChange,
}) => {
  const selectYes = () => {
    if (!onChange) {
      return;
    }
    onChange({ selected: true });
  };

  const selectNo = () => {
    if (paid) {
      return;
    }
    if (!onChange) {
      return;
    }
    onChange({ selected: false });
  };

  const changeQuantity = quantity => {
    if (Number(quantity) > 0) {
      onChange({ selected, quantity });
    }
  };

  const renderQuantityField = () => {
    if (!selected || !unit) {
      return <></>;
    }

    const inputProps = {
      min: unit.min || 1,
    };

    if (unit.max) {
      inputProps.max = unit.max;
    }

    return (
      <TextField
        label="Quantity"
        variant="outlined"
        size="small"
        type="number"
        value={quantity}
        onChange={event => changeQuantity(event.target.value)}
        inputProps={inputProps}
        style={{
          width: '100px',
          marginTop: '15px',
        }}
      />
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Line />
      </Grid>
      <Grid item xs={10}>
        <BlockTitle>{title}</BlockTitle>
      </Grid>
      <Grid item xs={2} align="right">
        <Price>
          {price} {currency}
        </Price>
      </Grid>
      <Grid item xs={12}>
        <Text>{description}</Text>
      </Grid>
      <Grid item xs={12}>
        <StyledButton
          className={[selected ? 'selected' : '']}
          onClick={selectYes}
        >
          Yes
        </StyledButton>
        <StyledButton
          className={[!selected ? 'selected' : '']}
          onClick={selectNo}
        >
          No
        </StyledButton>
        {renderQuantityField()}
      </Grid>
      <Grid item xs={12} />
    </Grid>
  );
};
