import React, { useState, useEffect } from 'react';
import AddOn from './AddOn';
import Text from '../../../common/Text';
import Button from '../../../common/Button';
import Loading from '../../../common/Loading';
import { SubHeadline } from '../../../common/Headline';
import Breadcrumbs from '../../Breadcrumbs';

export default ({ application, addons, onChange }) => {
  const [changedAddOns, setChangedAddOns] = useState(addons);
  const [updating, setUpdating] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    setChangedAddOns(addons);
  }, [addons]);

  useEffect(() => {
    if (application) {
      setBreadcrumbs([
        {
          label: 'Applications',
          to: '/mybunac/applications',
        },
        {
          label: application.programmeDetails.title,
        },
        {
          label: 'Addons',
        },
      ]);
    }
  }, [application]);

  if (!application || !addons) {
    return <Loading text="Loading Product information" />;
  }

  const changeAddon = id => ({ selected, quantity }) => {
    setChangedAddOns(
      changedAddOns.map(addon => {
        if (addon.id !== id) {
          return addon;
        }
        return {
          ...addon,
          selected,
          quantity,
        };
      })
    );
  };

  const submit = async () => {
    await setUpdating(true);
    await onChange(changedAddOns);
    await setUpdating(false);
  };

  return (
    <div>
      <SubHeadline>Choose Add-Ons for your Programme</SubHeadline>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Text>
        You have the option to additionally book assistance for completing all
        of the formalities.
      </Text>
      {changedAddOns.map(addon => (
        <AddOn
          key={addon.id}
          addon={addon}
          currency={application.currency}
          onChange={changeAddon(addon.id)}
        />
      ))}

      {!updating && (
        <Button
          variant="primary"
          submit
          label="Submit Information"
          onClick={submit}
        />
      )}
      {updating && <Loading text="Updating your Add-On selection" />}
    </div>
  );
};
