import styled from 'styled-components';

export default styled.span`
  color: #000000;
  font-family: Arial;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
  text-decoration: underline;
`;
